@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@font-face {
    font-family: marker;
    src: url(./assets/fonts/marker.ttf);
}

body {
    background: rgb(57,65,80);
    background: radial-gradient(circle, rgba(57,65,80,1) 35%, rgba(40,44,52,1) 100%);
    color: white;
    font-family: 'Open Sans', sans-serif;
}



h2 {
    font-family: marker;
    font-size: 46px;
    font-weight: normal;
    line-height: 125%;
}